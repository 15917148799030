<template>
  <div class="history_main">

    <!--  交易记录-->
    <van-nav-bar title="交易记录" left-text="" right-text="" left-arrow @click-left="onClickLeft" />
    <div v-if="arr.length == 0" style="text-align: center;margin-top: 9.0541rem;color: #999999;font-size: 18px;">暂无交易记录
    </div>

    <!--  -->
    <div class="history_infos" v-for="(item, index) in arr" :key="index">
      <div style="height:41px;display: flex;justify-content: space-between;align-items: center;">
        <div style="
color: #999999;">{{ item.updateTime }}</div>
        <div class="lianghao">
          <p v-if="item.payStatus == 2" style="color:#FF4D42;font-size: 0.3784rem;">已退款</p>
          <p v-if="item.payStatus == 1" style="color:#00DC9A;font-size: 0.3784rem;">已支付</p>
          <!-- <img :src="require('../img/xiazai.png')" alt=""
            style="height:12px;width: 12px;margin-right: 3px;"><span>下载相关协议</span> -->
          <!-- <p v-if="item.searchCategory == 2">靓号</p> -->
        </div>
      </div>
      <div
        style="height:98px;display: flex; flex-direction: column;justify-content: space-around;border-top: 1px solid #F6F6F6; ">
        <div style="font-size: 0.3784rem;"><span style="margin-right:5px">套餐号码:</span> <span>{{ item.goodsNumber }}</span>
        </div>
        <div style="
color: #999999;font-size: 0.3243rem;">{{ item.adress }}</div>
      </div>
    </div>
  </div>
</template>
<script>

// import { Toast } from 'vant'
import { searchDingdanInfo } from "@/api/lianghao/index";


export default {
  data() {
    return {
      arr: [],
      id: '',
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
  },
  mounted() {
    this.getlist()
  },
  methods: {
    onClickLeft() {
      // this.$router.push('lianghao')
      this.$router.replace(
        `lianghao?id=${this.id}`
      );
    },
    getlist() {
      console.log(this.id)
      searchDingdanInfo({ userId: this.id }).then(res => {
        // console.log(res)
        if (res.data.code == 0) {
          console.log(res)
          // this.arr = res.data.data
          let result = res.data.data
          result.map(item => {
            if (item.consigneePhone) {
              item.consigneePhone = item.consigneePhone.toString().slice(0, 3) + '****' + item.consigneePhone.toString().slice(7, 11)

            }

            item.adress = item.consigneeName + ' ' + item.consigneePhone + ' ' + item.province + '' + item.city + '' + item.distrct + '' + item.consigneeAddress
            return item
          })
          // console.log(result)
          this.arr = result
          // this.arr = result

        }
      })
    }
  }

};
</script>

<style scoped lang="less">
.history_infos {
  box-sizing: border-box;
  width: 95%;
  height: 140px;
  background: #FFFFFF;
  box-sizing: border-box;
  height: 140px;
  // background: #9a2626;
  border-radius: 8px;
  padding: 0 14px;
  margin: 0 auto;
  margin-top: 12px;
  // padding-bottom: 0.2703rem;

}

.history_main {
  height: 100%;
  background: #F6F6F6;
}

.lianghao {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #007AFF;
}
</style>